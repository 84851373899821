import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.contacts;

export const getBrokerContactsSelector = createSelector(getState, (state) => state.brokerContacts);
export const getBrokerContactsMetaSelector = createSelector(
  getState,
  (state) => state.brokerContactsMeta,
);
export const isLoadingBrokerContactsSelector = createSelector(
  getState,
  (state) => state.isLoadingBrokerContacts,
);

export const isLoadingCreateContactSelector = createSelector(
  getState,
  (state) => state.isLoadingCreateContact,
);

export const isLoadingUpdateContactSelector = createSelector(
  getState,
  (state) => state.isLoadingUpdateContact,
);

export const getCarrierContactSelector = createSelector(getState, (state) => state.carrierContact);
export const isLoadingCarrierContactSelector = createSelector(
  getState,
  (state) => state.isLoadingCarrierContact,
);

export const carrierContactsSelector = createSelector(getState, (state) => state.carrierContacts);
export const isLoadingCarrierContactsSelector = createSelector(
  getState,
  (state) => state.isLoadingCarrierContacts,
);

export const selectedContactSelector = createSelector(getState, (state) => state.selectedContact);
export const originalContactSelector = createSelector(getState, (state) => state.originalContact);
export const isVisibleEditContactSelector = createSelector(
  getState,
  (state) => state.isVisibleEditContact,
);
