import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { auth } from 'src/app/database';

import {
  setAgencyAccountsMeta,
  setAgencyMembersMeta,
  setAgencyPendingInvitesMeta,
  setAgencyProposalsMeta,
  setIsInviteModal,
} from './agency.slice';

import { ERROR } from 'src/constants/errorNames';
import { STRIPE_PUBLISHABLE_KEY } from 'src/constants/env';

import api from 'src/app/apiSingleton';
import { reportingColors } from 'src/constants/reportingColros';

// eslint-disable-next-line no-undef
const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);

export const createBrokerAgency = createAsyncThunk(
  'agency/create-broker-agency ',
  async (data, thunkApi) => {
    try {
      const { payload, navigate } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.agency.createBrokerAgency(payload);

      toast('Broker Agency has been created successfully', { type: 'success' });

      navigate(`/teams/${response.id}/settings?activeTab=billing`);

      thunkApi.dispatch(getBrokerAgencies());

      return response;
    } catch (err) {
      console.error(err);
    }
  },
);

export const updateBrokerAgency = createAsyncThunk(
  'agency/update-broker-agency',
  async (data, thunkApi) => {
    try {
      const { payload, id } = data;
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.agency.updateBrokerAgency(id, payload);

      toast('Your changes was successfully saved!', { type: 'success' });
      thunkApi.dispatch(getBrokerAgency(response?.id));
    } catch (err) {
      console.error(err);
    }
  },
);

export const getBrokerAgency = createAsyncThunk(
  'agency/get-broker-agency',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.agency.getBrokerAgency(data);

      return response;
    } catch (err) {
      console.error(err);
    }
  },
);

export const getBrokerAgencies = createAsyncThunk('agency/get-broker-agencies', async (data) => {
  try {
    const { isSubscription, navigate } = data;

    const token = await auth.currentUser.getIdToken();

    const user_id = await auth.currentUser?.uid;

    api.apiClient.setToken(token);

    const response = await api.agency.getBrokerAgencies();

    const brokerAgency = response?.find((item) => item.owner_id === user_id);

    if (navigate) {
      if (brokerAgency) {
        if (isSubscription) {
          navigate(`/teams/${brokerAgency.id}/settings?activeTab=billing`);
        }
      }

      if (!brokerAgency) {
        if (isSubscription) {
          navigate(`/teams/create`);
        }
      }
    }

    return response;
  } catch (err) {
    console.error(err);
  }
});

export const inviteTeamMember = createAsyncThunk(
  'agency/invite-team-member',
  async (data, thunkApi) => {
    try {
      const { id, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.agency.inviteTeamMember(id, payload);

      thunkApi.dispatch(getPendingInvites(id));

      toast('Your invite has been successfully sent!', { type: 'success' });
    } catch (err) {
      toast(err.error?.replace('Error: ', ''), { type: 'error' });
    }
  },
);

export const resendInviteTeamMember = createAsyncThunk(
  'agency/resend-invite-team-member',
  async (data, thunkApi) => {
    try {
      const { id, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.agency.inviteTeamMember(id, payload);

      thunkApi.dispatch(getPendingInvites(id));

      toast('Your invite has been successfully sent!', { type: 'success' });
    } catch (err) {
      toast(err.error?.replace('Error: ', ''), { type: 'error' });
    }
  },
);

export const getAgencyMembers = createAsyncThunk(
  'agency/get-agency-members',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.agency.getAgencyMembers(data);

      thunkApi.dispatch(setAgencyMembersMeta(response.meta));

      return response.items;
    } catch (err) {
      console.error(err);
    }
  },
);

export const deleteAgencyMember = createAsyncThunk(
  'agency/delete-agency-member',
  async (data, thunkApi) => {
    try {
      const { teamId, memberId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.agency.deleteAgencyMember(teamId, memberId);

      thunkApi.dispatch(getBrokerAgency(teamId));

      toast('User has been successfully deleted', { type: 'success' });

      thunkApi.dispatch(getAgencyMembers(teamId));
    } catch (err) {
      console.error(err);
    }
  },
);

export const getPendingInvites = createAsyncThunk(
  'agency/get-pending-invites',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.agency.getPendingInvites(data);

      thunkApi.dispatch(setAgencyPendingInvitesMeta(response.meta));

      return response.items;
    } catch (err) {
      console.error(err);
    }
  },
);

export const deletePendingInvite = createAsyncThunk(
  'agency/delete-pending-invite',
  async (data, thunkApi) => {
    try {
      const { teamId, memberId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.agency.deletePendingInvite(teamId, memberId);

      toast('User has been successfully deleted', { type: 'success' });

      thunkApi.dispatch(getPendingInvites(teamId));
    } catch (err) {
      console.error(err);
    }
  },
);

export const getAgencyUserRole = createAsyncThunk('agency/get-agency-user-role', async (data) => {
  try {
    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    const response = await api.agency.getAgencyUserRole(data);

    return response?.role;
  } catch (err) {
    console.error(err);
  }
});

export const updateUserRole = createAsyncThunk('agency/update-user-role', async (data) => {
  try {
    const { teamId, memberId, payload } = data;
    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    await api.agency.updateUserRole(teamId, memberId, payload);

    toast('Role has been updated', { type: 'success' });
  } catch (err) {
    console.error(err);
    toast(err.error, { type: 'error' });
  }
});

export const acceptOrganizationInvite = createAsyncThunk(
  'agency/accept-organization',
  async (data, thunkApi) => {
    try {
      const { invite_id } = data;
      const token = await auth.currentUser.getIdToken();

      const payload = {
        invite_id,
      };

      api.apiClient.setToken(token);

      const response = await api.agency.acceptOrganizationInvite(payload);

      thunkApi.dispatch(setIsInviteModal(true));

      toast('Team invite was successfully accepted', { type: 'success' });

      return response;
    } catch (err) {
      const { navigate } = data;

      thunkApi.dispatch(setIsInviteModal(false));

      navigate('/broker/accounts');

      if (err?.error?.replace('Error: ', '') === 'resource not found') {
        toast('Invalid invite', {
          type: 'error',
        });
      } else {
        toast(err?.error?.replace('Error: ', ''), {
          type: 'error',
        });
      }

      return err;
    }
  },
);

export const getAgencyAccounts = createAsyncThunk(
  'agency/get-agency-accounts',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = { ...data };

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const response = await api.agency.getAgencyAccounts(payload);

      const updatedAccounts = [];

      for (const account of response.items) {
        const primaryContact = account?.contacts?.find((item) => item?.contact_type === 'primary');

        const payload = {
          ...account,
          contact_name: primaryContact?.name,
          contact_email: primaryContact?.email,
        };

        updatedAccounts.push(payload);
      }

      thunkApi.dispatch(setAgencyAccountsMeta(response.meta));

      return updatedAccounts;
    } catch (err) {
      console.error(err);
    }
  },
);

export const getAgencyProposals = createAsyncThunk(
  'agency/get-agency-proposals',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = { ...data };

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const response = await api.agency.getAgencyProposalsList(payload);

      const agencyAccounts = (
        await thunkApi.dispatch(getAgencyAccounts({ 'f[org_id]': data?.['f[org_id]'] }))
      ).payload;

      thunkApi.dispatch(setAgencyProposalsMeta(response.meta));

      return response?.items?.map((item) => {
        const company_name = agencyAccounts?.find(
          (account) => account.id === item.account_id,
        )?.company_name;

        return {
          ...item,
          company_name,
        };
      });
    } catch (err) {
      console.error(err);
    }
  },
);

export const getAgencyAccountProposals = createAsyncThunk(
  'agency/get-agency-account-proposals',
  async (data, thunkApi) => {
    try {
      const { id } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = { ...data };

      for (const item in payload) {
        if (!payload[item]) {
          delete payload[item];
        }
      }

      const response = await api.agency.getAgencyAccountProposals(id, payload);

      thunkApi.dispatch(setAgencyProposalsMeta(response.meta));

      return response.items;
    } catch (err) {
      console.error(err);
    }
  },
  [],
);

export const createAgencyCheckoutSession = createAsyncThunk(
  'agency/create-agency-checkout-session',
  async (data) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.agency.createCheckoutSession(data);

      await stripe.redirectToCheckout({ sessionId: response.id });
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, { type: 'error' });
    }
  },
);

export const createAgencyCustomerPortalSession = createAsyncThunk(
  'agency/create-agency-customer-portal-session',
  async (data) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.agency.createCustomerPortalSession(data);

      return (window.location.href = response.url);
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, { type: 'error' });
    }
  },
);

export const getAgencyReporting = createAsyncThunk('agency/get-agency-reporting', async (data) => {
  try {
    const { startDate, endDate, interval, org_id } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    const payload = {
      start: startDate,
      end: endDate,
      interval,
    };

    for (const item in payload) {
      if (!payload[item]) {
        delete payload[item];
      }
    }

    const response = await api.agency.getAgencyReporting(org_id, payload);

    const updatedDatasets = [];

    for (const [index, dataset] of response.data.datasets.entries()) {
      const payload = {
        ...dataset,
        backgroundColor: reportingColors[index],
      };

      updatedDatasets.push(payload);
    }

    const updatedResponse = {
      datasets: updatedDatasets,
      labels: response.data.labels,
    };

    return updatedResponse;
  } catch (err) {
    toast(ERROR.SYSTEM_ERROR, { type: 'error' });
  }
});

export const updateSubscriptionQuantity = createAsyncThunk(
  'agency/update-subscription-quantity',
  async (data, thunkApi) => {
    try {
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.agency.updateSubscriptionQuantity(data);

      thunkApi.dispatch(getBrokerAgency(data.org_id));
      thunkApi.dispatch(getPendingInvites(data.org_id));
      thunkApi.dispatch(getAgencyMembers(data.org_id));

      toast('Subscription quantity successfully updated', { type: 'success' });

      return response;
    } catch (err) {
      toast(err.error, { type: 'error' });
    }
  },
);
