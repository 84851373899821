import React from 'react';

import { CustomTable } from '../CustomTable/CustomTable';
import { useTable } from './useTable';

export const BrokerProposalsTable = () => {
  const { build } = useTable();

  return <CustomTable build={build} />;
};
