import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  getBrokerAccounts,
  getBrokerAccountsMetaSelector,
  getBrokerAccountsSelector,
  getIsLoadingBrokerAccounts,
  setAccountMode,
} from 'src/store/broker';
import {
  agencyAccountsMetaSelector,
  agencyAccountsSelector,
  getAgencyAccounts,
  isLoadingAgencyAccountsSelector,
} from 'src/store/agency';

import { ROUTE } from 'src/constants/routes';

export const useTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const teamId = params?.teamId;

  const brokerAccounts = useSelector(getBrokerAccountsSelector);
  const agencyAccounts = useSelector(agencyAccountsSelector);

  const brokerAccountsMeta = useSelector(getBrokerAccountsMetaSelector);
  const agencyAccountsMeta = useSelector(agencyAccountsMetaSelector);

  const isLoadingBrokerAccounts = useSelector(getIsLoadingBrokerAccounts);
  const isLoadingAgencyAccounts = useSelector(isLoadingAgencyAccountsSelector);

  const onClickAddAccount = useCallback(() => {
    dispatch(setAccountMode('CREATE'));
    navigate(!teamId ? ROUTE.BROKER_CREATE : `/teams/${teamId}/accounts/create`);
  }, [dispatch, navigate, teamId]);

  const build = {
    title: 'Accounts',
    filters: [
      {
        id: 'q',
        label: 'Account Name',
        type: 'search-input',
      },
      {
        id: 'button',
        type: 'button',
        options: {
          title: 'Add Account',
          onClick: onClickAddAccount,
          type: 'primary',
        },
      },
    ],
    columns: [
      {
        id: 'company_name',
        name: 'Account Name',
        value: (item) => (
          <Link to={teamId ? `/teams/${teamId}/accounts/${item.id}` : `${ROUTE.BROKER}/${item.id}`}>
            {item.company_name}
          </Link>
        ),
        sortable: true,
      },
      {
        id: 'contact_name',
        name: 'Contact Name',
        sortable: true,
      },
      {
        id: 'contact_email',
        name: 'Contact Email',
        sortable: true,
      },
      {
        id: 'fips_code',
        name: 'County',
        sortable: true,
      },
      {
        id: 'renewal_date',
        name: 'Renewal Date',
        value: (item) => moment(item.renewal_date).format('MM/DD/YYYY'),
        sortable: true,
      },
      {
        id: 'created_timestamp',
        name: 'Created',
        value: (item) => moment(item.created_timestamp).format('lll'),
        sortable: true,
      },
    ],
    data: !teamId ? brokerAccounts : agencyAccounts,
    meta: !teamId ? brokerAccountsMeta : agencyAccountsMeta,
    api: {
      get: !teamId ? getBrokerAccounts : getAgencyAccounts,
    },
    is_loading: isLoadingBrokerAccounts || isLoadingAgencyAccounts,
  };

  return { build };
};
