import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import _ from 'lodash';

import { MUISelect } from '../MuiSelect/MuiSelect';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { EmptyField } from '../EmptyField/EmptyField';
import { Loader, LoaderWrapper } from '../Loader/Loader';
import { PageHeader } from '../PageHeader/PageHeader';

import classes from './customTable.module.scss';

const SearchInput = ({ value, onChange, onClear, onClick, onKeyDown, label, isLoading }) => {
  return (
    <div className={classes.CustomTableSearch}>
      <div className={classes.CustomTableSearchWrapper}>
        <Input label={label} value={value} onChange={onChange} onKeyDown={onKeyDown} />
        {value && (
          <i onClick={onClear} className={`fas fa-plus ${classes.CustomTableSearchClear}`} />
        )}
      </div>
      <Button type="primary" icon="fas fa-search" onClick={onClick} isLoading={isLoading} />
    </div>
  );
};

const typeToComponent = {
  'search-input': SearchInput,
  select: MUISelect,
  button: Button,
};

export const CustomTable = (props) => {
  const { build } = props;

  const {
    title,
    filters = [],
    columns = [],
    data = [],
    meta = {},
    api = {},
    table_options = {},
    is_loading = false,
  } = build;

  const { get, get_options = {} } = api;
  const { onClickRow } = table_options;

  const dispatch = useDispatch();

  const params = useParams();
  const orgId = params?.teamId || '';

  const [searchData, setSearchData] = useState({
    q: '',
    page: meta?.page ? meta?.page : 1,
    per_page: meta?.per_page ? meta?.per_page : 10,
    sort: '',
  });

  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  useEffect(() => {
    if (get && !searchData?.q) {
      console.log('get_options', get_options);

      dispatch(get({ ...get_options, ...searchData, 'f[org_id]': orgId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, get, searchData, orgId]);

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = searchData?.sort === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSearchData((prev) => ({ ...prev, sort: `${property}` }));
      } else {
        setSearchData((prev) => ({ ...prev, sort: `-${property}` }));
      }
    }
  };

  const onChangeFilter = useCallback(
    (key) => (event) => {
      setSearchData((prev) => ({ ...prev, [key]: event?.target?.value }));
    },
    [],
  );

  const onClearFilter = useCallback(
    (key) => () => {
      setSearchData((prev) => ({ ...prev, [key]: '' }));
    },
    [],
  );

  const searchOnKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && searchData?.q) {
        return dispatch(get({ ...get_options, ...searchData, 'f[org_id]': orgId }));
      }
    },
    [dispatch, get, get_options, searchData, orgId],
  );

  const onClickSearchButton = useCallback(() => {
    dispatch(get({ ...get_options, ...searchData, 'f[org_id]': orgId }));
  }, [dispatch, get, get_options, orgId, searchData]);

  const onRowClick = useCallback(
    (item) => () => {
      if (onClickRow) {
        return onClickRow(item);
      }
    },
    [onClickRow],
  );

  const onPageChange = useCallback((event, page) => {
    setSearchData((prev) => ({ ...prev, page: page + 1 }));
  }, []);

  const onChangePageSize = useCallback((event) => {
    setSearchData((prev) => ({ ...prev, page: 1, per_page: parseFloat(event.target.value) }));
  }, []);

  console.log('filters', filters);

  return (
    <div className={classes.CustomTable}>
      {title && (
        <PageHeader title={title}>
          <div className={classes.CustomTableFilters}>
            {filters
              ?.filter((item) => !_.isEmpty(item))
              ?.map((item) => {
                const Component = !item?.component
                  ? typeToComponent?.[item?.type]
                  : item?.component;

                return (
                  <div className={classes.CustomTableFiltersItem} key={item.id}>
                    <Component
                      value={searchData?.[item?.id]}
                      label={item?.label}
                      options={item?.options}
                      onChange={onChangeFilter(item?.id)}
                      onClear={onClearFilter(item?.id)}
                      onKeyDown={searchOnKeyDown}
                      onClick={onClickSearchButton}
                      {...item?.options}
                    />
                  </div>
                );
              })}
          </div>
        </PageHeader>
      )}
      <Paper>
        {is_loading ? (
          <EmptyField>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </EmptyField>
        ) : (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns
                    ?.filter((item) => !_.isEmpty(item))
                    .map((item) => (
                      <TableCell key={item.id}>
                        {item.sortable ? (
                          <TableSortLabel
                            active={selectedCell === item.id}
                            direction={cellDirection}
                            onClick={onChangeCellSort(item.id)}
                          >
                            {item.name}
                          </TableSortLabel>
                        ) : (
                          item.name
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={onRowClick(item)}
                  >
                    {columns
                      ?.filter((item) => !_.isEmpty(item))
                      ?.map((column) => {
                        const value =
                          typeof column?.value === 'function'
                            ? column?.value(item)
                            : item?.[column?.id];

                        const style =
                          typeof column?.style === 'function' ? column?.style(item) : column?.style;

                        return (
                          <TableCell key={column.id} component="th" scope="row" sx={style}>
                            {value}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={meta?.total ?? 0}
          rowsPerPage={searchData?.per_page}
          page={searchData?.page - 1}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangePageSize}
        />
      </Paper>
    </div>
  );
};
