import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  getIsLoadingRepOtherRfpSelector,
  getIssuerDataSelector,
  getMyRequestedProposals,
  getRepOtherRfpMeta,
  getRepOtherRfpSelector,
} from 'src/store/issuer';

import { coverageOptions, dateOptions } from 'src/features/issuer/issuerRequests/issuerOptions';

export const useTable = () => {
  const navigate = useNavigate();

  const issuerData = useSelector(getIssuerDataSelector);
  const rfpsData = useSelector(getRepOtherRfpSelector);
  const rfpsMeta = useSelector(getRepOtherRfpMeta);
  const isLoading = useSelector(getIsLoadingRepOtherRfpSelector);

  const onClickRow = useCallback(
    (item) => {
      navigate(
        `/rep/my-rfps/accounts/${item?.account_id}/proposals/${item?.proposal_id}/account-info`,
      );
    },
    [navigate],
  );

  const build = {
    title: 'RFPs',
    filters: [
      {
        id: 'coverage_type',
        type: 'select',
        label: 'Coverage',
        options: coverageOptions,
      },

      {
        id: 'due_date',
        type: 'select',
        label: 'Due Date',
        options: dateOptions,
      },
      {
        id: 'q',
        label: 'Search',
        type: 'search-input',
      },
    ],
    columns: [
      {
        id: 'proposal_name',
        name: 'Proposal Name',
        sortable: true,
      },
      {
        id: 'company_name',
        name: 'Company Name',
        sortable: true,
      },
      {
        id: 'sic_code',
        name: 'Account SIC',
        sortable: true,
      },
      {
        id: 'account_zip_code',
        name: 'Account Zip Code',
        sortable: false,
      },
      {
        id: 'coverage',
        name: 'Coverage',
        value: (item) => item?.lines_of_coverage?.join(', '),
        sortable: false,
      },
      {
        id: 'group_size',
        name: 'Employee Count',
        sortable: false,
      },
      {
        id: 'broker',
        name: 'Broker',
        value: (item) => {
          return (
            <>
              {item?.broker?.display_name}
              <br />
              {item?.broker?.address_street && `${item?.broker?.address_street} `}
              {item?.broker?.address_city && `${item?.broker?.address_city}, `}
              {item?.broker?.address_state && `${item?.broker?.address_state} `}
              {item?.broker?.address_zip_code && `${item?.broker?.address_zip_code}`}
              <br />
              {item?.broker?.email && `${item?.broker?.email}`}
              <br />
              {item?.broker?.phone_number && `${item?.broker?.phone_number}`}
            </>
          );
        },
        sortable: false,
      },
      {
        id: 'effective_date',
        name: 'Effective Date',
        value: (item) => moment(item?.effective_date).format('l'),
        sortable: true,
      },
      {
        id: 'due_date',
        name: 'Due Date',
        value: (item) => moment(item?.due_date).format('l'),
        sortable: true,
        style: (item) => {
          return {
            color: moment().isAfter(moment(item?.due_date).format('MM-DD-YYYY')) ? 'red' : 'black',
          };
        },
      },
    ],
    data: rfpsData,
    meta: rfpsMeta,
    api: {
      get: getMyRequestedProposals,
      get_options: {
        repId: issuerData?.id,
      },
    },
    table_options: {
      onClickRow: (item) => onClickRow(item),
    },
    is_loading: isLoading,
  };

  return { build };
};
