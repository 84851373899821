import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import moment from 'moment';

import {
  getIsLoadingProposal,
  getProposalListSelector,
  getProposalsList,
  getProposalsMetaSelector,
} from 'src/store/broker';
import {
  agencyProposalsMetaSelector,
  agencyProposalsSelector,
  getAgencyProposals,
  isLoadingAgencyProposalsSelector,
} from 'src/store/agency';

import { Status } from '../Status/Status';

import { ROUTE } from 'src/constants/routes';

export const useTable = () => {
  const params = useParams();
  const teamId = params?.teamId;

  const brokerProposals = useSelector(getProposalListSelector);
  const agencyProposals = useSelector(agencyProposalsSelector);

  const brokerProposalsMeta = useSelector(getProposalsMetaSelector);
  const agencyProposalsMeta = useSelector(agencyProposalsMetaSelector);

  const isLoadingProposals = useSelector(getIsLoadingProposal);
  const isLoadingAgencyProposals = useSelector(isLoadingAgencyProposalsSelector);

  const statuses = [
    { title: 'All', value: '' },
    { title: 'Approved', value: 'approved' },
    { title: 'Submitted', value: 'submitted' },
    { title: 'Draft', value: 'draft' },
    { title: 'Rejected', value: 'rejected' },
  ];

  const build = {
    title: 'Proposals',
    filters: [
      {
        id: 'f[status]',
        type: 'select',
        label: 'Status',
        options: statuses,
      },
      {
        id: 'q',
        label: 'Proposal Name',
        type: 'search-input',
      },
    ],
    columns: [
      {
        id: 'name',
        name: 'Proposal Name',
        value: (item) => (
          <Link
            to={
              teamId
                ? `/teams/${teamId}/accounts/${item.account_id}/proposals/${item.id}/account-info`
                : `${ROUTE.BROKER}/${item.account_id}/proposals/${item.id}/account-info`
            }
          >
            {item.name}
          </Link>
        ),
        sortable: true,
      },
      {
        id: 'company_name',
        name: 'Account Name',
        value: (item) => (
          <Link
            to={
              teamId
                ? `/teams/${teamId}/accounts/${item.account_id}`
                : `${ROUTE.BROKER}/${item.account_id}`
            }
          >
            {item.company_name}
          </Link>
        ),
      },
      {
        id: 'status',
        name: 'Status',
        value: (item) => <Status status={item?.status} />,
        sortable: true,
      },
      {
        id: 'created_timestamp',
        name: 'Created',
        sortable: true,
        value: (item) => moment(item.created_timestamp).format('lll'),
      },
      {
        id: 'owner',
        name: 'Owner',
        value: (item) => (
          <Tooltip title={item?.broker?.email}>
            <>{item?.broker?.display_name}</>
          </Tooltip>
        ),
      },
    ],
    data: !teamId ? brokerProposals : agencyProposals,
    meta: !teamId ? brokerProposalsMeta : agencyProposalsMeta,
    api: {
      get: !teamId ? getProposalsList : getAgencyProposals,
    },
    is_loading: isLoadingProposals || isLoadingAgencyProposals,
  };

  return { build };
};
