import _ from 'lodash';
import moment from 'moment';
import { currencyFormatter } from 'src/constants/currency';

export const getNormalizedMemberAge = (dateOfBirth, quote) => {
  let memberAge = moment(quote?.effective_date).diff(moment(dateOfBirth), 'years');
  if (memberAge > 65) memberAge = 65;

  return memberAge;
};

export const sumPremium = (rates, members, quote, planToMap, basePlanRate) => {
  let updatedRates = [];
  for (let rate of rates) {
    let sumPremium = 0;
    let employerCost = 0;

    let planMembers = members?.filter(
      (item) => item.current_medical_plan_name === rate.current_plan_name,
    );
    for (const member of members) {
      if (quote?.quote_type === 'advanced') {
        if (planToMap) {
          if (member.current_medical_plan_name !== planToMap) {
            continue;
          }
        } else if (member.current_medical_plan_name !== rate.current_plan_name) {
          continue;
        }
      }

      let memberAge = moment(quote?.effective_date).diff(moment(member.date_of_birth), 'years');

      if (memberAge > 65) memberAge = 65;
      const memberRate = !_.isEmpty(basePlanRate)
        ? basePlanRate[`age_${memberAge}`]
        : rate[`age_${memberAge}`];
      const eeContributionPercent =
        quote?.contribution_type === 'percent'
          ? Number.parseInt(quote?.contribution_ee, 10) / 100
          : quote?.contribution_ee;
      const depContributionPercent =
        quote?.contribution_type === 'percent'
          ? Number.parseInt(quote?.contribution_dep, 10) / 100
          : quote?.contribution_dep;

      sumPremium += memberRate;

      if (member.relationship === 'employee') {
        if (quote?.contribution_type === 'percent') {
          employerCost +=
            Math.round((memberRate * eeContributionPercent + Number.EPSILON) * 100) / 100;
        }
        if (quote?.contribution_type === 'dollar') {
          if (eeContributionPercent < memberRate) {
            employerCost += memberRate - eeContributionPercent;
          }

          if (eeContributionPercent > memberRate) {
            // employerCost += memberRate;
          }
        }
      } else {
        if (quote?.contribution_type === 'percent') {
          employerCost +=
            Math.round((memberRate * depContributionPercent + Number.EPSILON) * 100) / 100;
        }

        if (quote?.contribution_type === 'dollar') {
          if (depContributionPercent < memberRate) {
            employerCost += memberRate - depContributionPercent;
          }

          if (depContributionPercent > memberRate) {
            // employerCost += memberRate;
          }
        }
      }
    }

    const updatedRate = {
      ...rate,
      employee_cost:
        quote?.contribution_type === 'percent' ? sumPremium - employerCost : employerCost,
      employer_cost:
        quote?.contribution_type === 'percent' ? employerCost : sumPremium - employerCost,
      total_premium: sumPremium,
      members: planMembers,
    };

    updatedRates.push(updatedRate);
  }

  return updatedRates?.sort((a, b) => b.members?.length - a.members?.length);
};

export const calculateEmployeeTotal = (rate, quote, members, selectedBasePlanQuote) => {
  const quoteMembers = [...members];
  const planMembers = quoteMembers.filter(
    (member) => member.current_medical_plan_name === rate.current_plan_name,
  );

  let sumPerPlan = 0;

  for (const member of planMembers) {
    let employerCost = 0;
    let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

    const memberRate = !_.isEmpty(selectedBasePlanQuote)
      ? selectedBasePlanQuote[`age_${memberAge}`]
      : rate[`age_${memberAge}`];

    const eeContributionPercent =
      quote?.contribution_type === 'percent'
        ? Number.parseInt(quote?.contribution_ee, 10) / 100
        : quote?.contribution_ee;
    const depContributionPercent =
      quote?.contribution_type === 'percent'
        ? Number.parseInt(quote?.contribution_dep, 10) / 100
        : quote?.contribution_dep;

    if (true) {
      if (member.relationship === 'employee') {
        if (quote?.contribution_type === 'percent') {
          employerCost += memberRate * eeContributionPercent;
        }
        if (quote?.contribution_type === 'dollar') {
          if (eeContributionPercent < memberRate) {
            employerCost += eeContributionPercent;
          }

          if (eeContributionPercent > memberRate) {
            employerCost += memberRate;
          }
        }
      } else {
        if (quote?.contribution_type === 'percent') {
          employerCost += memberRate * depContributionPercent;
        }
        if (quote?.contribution_type === 'dollar') {
          if (depContributionPercent < memberRate) {
            employerCost += depContributionPercent;
          }

          if (depContributionPercent > memberRate) {
            employerCost += memberRate;
          }
        }
      }
    }

    employerCost =
      quote?.contribution_type === 'percent'
        ? Math.round((employerCost + Number.EPSILON) * 100) / 100
        : employerCost;

    sumPerPlan += rate[`age_${memberAge}`] - employerCost;
  }

  return currencyFormatter(sumPerPlan);
};

export const calculateMemberRate = (
  member,
  rate,
  quote,
  selectedBasePlanQuote,
  withContribution = true,
) => {
  let employerCost = 0;
  let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

  const memberRate = !_.isEmpty(selectedBasePlanQuote)
    ? selectedBasePlanQuote[`age_${memberAge}`]
    : rate[`age_${memberAge}`];

  const eeContributionPercent =
    quote?.contribution_type === 'percent'
      ? Number.parseInt(quote?.contribution_ee, 10) / 100
      : quote?.contribution_ee;
  const depContributionPercent =
    quote?.contribution_type === 'percent'
      ? Number.parseInt(quote?.contribution_dep, 10) / 100
      : quote?.contribution_dep;

  if (withContribution) {
    if (member.relationship === 'employee') {
      if (quote?.contribution_type === 'percent') {
        employerCost += memberRate * eeContributionPercent;
      }
      if (quote?.contribution_type === 'dollar') {
        if (eeContributionPercent < memberRate) {
          employerCost += eeContributionPercent;
        }

        if (eeContributionPercent > memberRate) {
          employerCost += memberRate;
        }
      }
    } else {
      if (quote?.contribution_type === 'percent') {
        employerCost += memberRate * depContributionPercent;
      }
      if (quote?.contribution_type === 'dollar') {
        if (depContributionPercent < memberRate) {
          employerCost += depContributionPercent;
        }

        if (depContributionPercent > memberRate) {
          employerCost += memberRate;
        }
      }
    }
  }

  employerCost =
    quote?.contribution_type === 'percent'
      ? Math.round((employerCost + Number.EPSILON) * 100) / 100
      : employerCost;

  if (quote?.quote_type === 'advanced') {
    return rate[`age_${memberAge}`] - employerCost;
  }

  if (quote?.quote_type !== 'advanced') {
    return memberRate - employerCost;
  }
};

export const calculateEmployerTotal = (rate, quote, members, selectedBasePlanQuote) => {
  const quoteMembers = [...members];
  const planMembers = quoteMembers.filter(
    (member) => member.current_medical_plan_name === rate.current_plan_name,
  );

  let totalCost = 0;

  for (const member of planMembers) {
    let employerCost = 0; // need to reset this each iteration
    let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

    const memberRate = !_.isEmpty(selectedBasePlanQuote)
      ? selectedBasePlanQuote[`age_${memberAge}`]
      : rate[`age_${memberAge}`];

    const eeContributionPercent =
      quote?.contribution_type === 'percent'
        ? Number.parseInt(quote?.contribution_ee, 10) / 100
        : quote?.contribution_ee;
    const depContributionPercent =
      quote?.contribution_type === 'percent'
        ? Number.parseInt(quote?.contribution_dep, 10) / 100
        : quote?.contribution_dep;

    if (member.relationship === 'employee') {
      if (quote?.contribution_type === 'percent') {
        employerCost +=
          Math.round((memberRate * eeContributionPercent + Number.EPSILON) * 100) / 100;
      }

      if (quote?.contribution_type === 'dollar') {
        if (eeContributionPercent < memberRate) {
          employerCost += eeContributionPercent;
        }

        if (eeContributionPercent > memberRate) {
          employerCost += memberRate;
        }
      }
    } else {
      if (quote?.contribution_type === 'percent') {
        employerCost +=
          Math.round((memberRate * depContributionPercent + Number.EPSILON) * 100) / 100;
      }
      if (quote?.contribution_type === 'dollar') {
        if (depContributionPercent < memberRate) {
          employerCost += depContributionPercent;
        }

        if (depContributionPercent > memberRate) {
          employerCost += memberRate;
        }
      }
    }

    if (quote?.quote_type === 'advanced') {
      if (rate[`age_${memberAge}`] > employerCost) {
        totalCost += employerCost;
      } else {
        totalCost += rate[`age_${memberAge}`];
      }
    }

    if (quote?.quote_type !== 'advanced') {
      totalCost += employerCost;
    }
  }

  return currencyFormatter(totalCost);
};

export const calculateEmployerCost = (member, rate, quote, selectedBasePlanQuote) => {
  let employerCost = 0;
  let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

  const memberRate = !_.isEmpty(selectedBasePlanQuote)
    ? selectedBasePlanQuote[`age_${memberAge}`]
    : rate[`age_${memberAge}`];

  const eeContributionPercent =
    quote?.contribution_type === 'percent'
      ? Number.parseInt(quote?.contribution_ee, 10) / 100
      : quote?.contribution_ee;
  const depContributionPercent =
    quote?.contribution_type === 'percent'
      ? Number.parseInt(quote?.contribution_dep, 10) / 100
      : quote?.contribution_dep;

  if (member.relationship === 'employee') {
    if (quote?.contribution_type === 'percent') {
      employerCost += Math.round((memberRate * eeContributionPercent + Number.EPSILON) * 100) / 100;
    }
    if (quote?.contribution_type === 'dollar') {
      if (eeContributionPercent < memberRate) {
        employerCost += eeContributionPercent;
      }

      if (eeContributionPercent > memberRate) {
        employerCost += memberRate;
      }
    }
  } else {
    if (quote?.contribution_type === 'percent') {
      employerCost +=
        Math.round((memberRate * depContributionPercent + Number.EPSILON) * 100) / 100;
    }
    if (quote?.contribution_type === 'dollar') {
      if (depContributionPercent < memberRate) {
        employerCost += depContributionPercent;
      }

      if (depContributionPercent > memberRate) {
        employerCost += memberRate;
      }
    }
  }

  if (quote?.quote_type === 'advanced') {
    const valueToDecrease = rate[`age_${memberAge}`] - employerCost;
    if (valueToDecrease < 0) {
      return employerCost + valueToDecrease;
    } else {
      return employerCost;
    }
  }

  if (quote?.quote_type !== 'advanced') {
    return employerCost;
  }
};

export const calculatePlanTotal = (rate, quote, members, selectedBasePlanQuote) => {
  const quoteMembers = [...members];
  const planMembers = quoteMembers.filter(
    (item) => item.current_medical_plan_name === rate.current_plan_name,
  );

  let employerCost = 0;
  let sumCost = 0;

  for (const member of planMembers) {
    let memberAge = getNormalizedMemberAge(member.date_of_birth, quote);

    const memberRate = !_.isEmpty(selectedBasePlanQuote)
      ? selectedBasePlanQuote[`age_${memberAge}`]
      : rate[`age_${memberAge}`];

    const eeContributionPercent = Number.parseInt(quote?.contribution_ee, 10) / 100;
    const depContributionPercent = Number.parseInt(quote?.contribution_dep, 10) / 100;

    if (true) {
      if (member.relationship === 'employee') {
        employerCost += memberRate * eeContributionPercent;
      } else {
        employerCost += memberRate * depContributionPercent;
      }
    }

    employerCost = Math.round((employerCost + Number.EPSILON) * 100) / 100;
    sumCost += rate[`age_${memberAge}`];
  }
  return currencyFormatter(sumCost);
};
