// eslint-disable-next-line no-unused-vars
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AddContacts } from 'src/features/broker/addContacts/AddContacts';
import {
  carrierContactsSelector,
  deleteAgencyContact,
  deleteBrokerContact,
  getAllAgencyContacts,
  getAllBrokerContacts,
  getBrokerContactsMetaSelector,
  getBrokerContactsSelector,
  getCarrierContacts,
  isLoadingBrokerContactsSelector,
  isLoadingCarrierContactsSelector,
  setIsVisibleEditContact,
  setOriginalContact,
  setSelectedContact,
} from 'src/store/contacts';
import { Button } from '../Button/Button';
import { getBrokerData } from 'src/store/broker';

export const useTable = (props) => {
  console.log('props', props);

  const { onClickAdd, mode = 'add' } = props;

  const dispatch = useDispatch();

  const params = useParams();
  const teamId = params?.teamId;

  const brokerContacts = useSelector(getBrokerContactsSelector);
  const brokerContactsMeta = useSelector(getBrokerContactsMetaSelector);
  const brokerData = useSelector(getBrokerData);

  const carrierContacts = useSelector(carrierContactsSelector);
  const isLoadingCarrierContacts = useSelector(isLoadingCarrierContactsSelector);

  const isLoadingBrokerContacts = useSelector(isLoadingBrokerContactsSelector);

  const onClickEditIcon = useCallback(
    (item) => () => {
      dispatch(setSelectedContact(item));
      dispatch(setOriginalContact(item));
      dispatch(setIsVisibleEditContact(true));
    },
    [dispatch],
  );

  const onDeleteContact = useCallback(
    (item) => () => {
      if (!teamId) {
        const payload = {
          brokerId: brokerData?.id,
          contactId: item.id,
        };

        dispatch(deleteBrokerContact(payload));
      }

      if (teamId) {
        const payload = {
          org_id: teamId,
          contactId: item.id,
        };

        dispatch(deleteAgencyContact(payload));
      }
    },
    [dispatch, teamId, brokerData?.id],
  );

  const prepareGetRequests = useCallback(() => {
    if (mode !== 'share') {
      return !teamId ? getAllBrokerContacts : getAllAgencyContacts;
    }

    return getCarrierContacts;
  }, [mode, teamId]);

  const preparedRrequest = prepareGetRequests();

  const contacts = mode !== 'share' ? brokerContacts : carrierContacts.items;
  const contactsMeta = mode !== 'share' ? brokerContactsMeta : carrierContacts.meta;

  console.log('preparedRrequest', preparedRrequest);

  const build = {
    title: 'Contacts',
    filters: [
      ...(mode !== 'share'
        ? [
            {
              id: 'add-button',
              type: 'button',
              component: AddContacts,
            },
          ]
        : [{}]),
      ...(mode === 'share'
        ? [
            {
              id: 'q',
              label: 'Search',
              type: 'search-input',
            },
          ]
        : [{}]),
    ],
    columns: [
      {
        id: 'display_name',
        name: 'Rep Name',
        sortable: true,
      },
      {
        id: 'issuer_name',
        name: 'Carrier',
        sortable: false,
      },
      {
        id: 'email',
        name: 'Rep Email',
        sortable: true,
      },
      {
        id: 'lines',
        name: 'Lines',
        value: (item) => item?.lines?.join(', '),
        sortable: true,
      },
      {
        id: 'markets',
        name: 'Markets',
        value: (item) => item?.markets?.join(', '),
        sortable: true,
      },
      {
        id: '1',
        name: 'Group size',
        value: (item) => `${item?.min_group_size || 0} - ${item?.max_group_size}`,
      },
      ...(mode === 'add'
        ? [
            {
              id: '2',
              name: '',
              value: (item) => <i className="fas fa-edit" onClick={onClickEditIcon(item)} />,
              style: {
                color: '#209cee',
              },
            },
          ]
        : [{}]),
      ...(mode === 'add'
        ? [
            {
              id: '3',
              name: '',
              value: (item) => <i className="fa fa-trash-o" onClick={onDeleteContact(item)} />,
              style: {
                color: '#ff0000',
              },
            },
          ]
        : [{}]),
      ...(mode === 'share'
        ? [
            {
              id: '3',
              name: '',
              value: (item) => (
                <Button title="Add to contact" type="primary" onClick={onClickAdd(item)} size="s" />
              ),
            },
          ]
        : [{}]),
    ],
    data: contacts,
    meta: contactsMeta,
    api: {
      get: preparedRrequest,
    },
    is_loading: isLoadingBrokerContacts || isLoadingCarrierContacts,
  };

  return { build };
};
