import React from 'react';

import { useTable } from './useTable';
import { CustomTable } from '../CustomTable/CustomTable';

export const AccountTable = () => {
  const { build } = useTable();

  return <CustomTable build={build} />;
};
