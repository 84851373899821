import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  getIsLoadingProposals,
  getProposalsList,
  getProposalsListSelector,
  getProposalsMetaSelector,
} from 'src/store/proposals';
import {
  agencyAccountProposalsSelector,
  agencyProposalsMetaSelector,
  getAgencyAccountProposals,
  isLoadingAgencyAccountProposalsSelector,
} from 'src/store/agency';

import { Status } from '../Status/Status';

import { ROUTE } from 'src/constants/routes';

export const useTable = () => {
  const navigate = useNavigate();
  const params = useParams();
  const teamId = params?.teamId;
  const id = params?.id;

  const proposalsList = useSelector(getProposalsListSelector);
  const agencyProposalsList = useSelector(agencyAccountProposalsSelector);

  const proposalsMeta = useSelector(getProposalsMetaSelector);
  const agencyProposalsMeta = useSelector(agencyProposalsMetaSelector);

  const isLoadingProposals = useSelector(getIsLoadingProposals);
  const isLoadingAgencyProposals = useSelector(isLoadingAgencyAccountProposalsSelector);

  const onAddNewProposal = useCallback(() => {
    navigate(ROUTE.PROPOSAL_CREATE);
  }, [navigate]);

  const build = {
    title: 'Proposals',
    filters: [
      {
        id: 'q',
        label: 'Proposal Name',
        type: 'search-input',
      },
      {
        id: 'button',
        type: 'button',
        options: {
          title: 'New Proposal',
          onClick: onAddNewProposal,
          type: 'primary',
        },
      },
    ],
    columns: [
      {
        id: 'name',
        name: 'Proposal Name',
        value: (item) => <Link to={`proposals/${item.id}/account-info`}>{item.name}</Link>,
        sortable: true,
      },
      {
        id: 'status',
        name: 'Status',
        value: (item) => <Status status={item?.status} />,
        sortable: true,
      },
      {
        id: 'created_timestamp',
        name: 'Created',
        value: (item) => moment(item.created_timestamp).format('lll'),
        sortable: true,
      },
    ],
    data: !teamId ? proposalsList : agencyProposalsList,
    meta: !teamId ? proposalsMeta : agencyProposalsMeta,
    api: {
      get: !teamId ? getProposalsList : getAgencyAccountProposals,
      get_options: { id },
    },
    is_loading: !teamId ? isLoadingProposals : isLoadingAgencyProposals,
  };

  return { build };
};
