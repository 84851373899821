import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { isLoadingUpdateContactSelector, setSelectedContact } from 'src/store/contacts';

import { Input } from '../Input/Input';
import { Modal } from '../Modal/Modal';
import { SearchSelect } from '../SearchSelect/SearchSelect';
import { Button } from '../Button/Button';

import { stateCodes } from 'src/constants/stateCodes';

import PurpleCircle from 'src/assets/app/purpleCircle.svg';
import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import classes from './editContactModal.module.scss';

export const EditContactModal = (props) => {
  const { onCloseEditModal, onUpdateContact, selectedContact, originalContactData } = props;

  const dispatch = useDispatch();

  const isLoadingUpdate = useSelector(isLoadingUpdateContactSelector);

  const [codes, setCodes] = useState(stateCodes);
  const [stateSearchValue, setStateSearchValue] = useState('');

  useEffect(() => {
    if (!_.isEmpty(selectedContact)) {
      let codes = stateCodes;

      for (const item of selectedContact?.markets) {
        const updatedCodes = _.reject(codes, (el) => el === item);

        codes = updatedCodes;
      }

      setCodes(codes);
    }
  }, [selectedContact]);

  const onSelectProductLine = useCallback(
    (item) => () => {
      const payload = {
        ...selectedContact,
        lines: [...selectedContact?.lines, item],
      };

      dispatch(setSelectedContact(payload));
    },
    [dispatch, selectedContact],
  );

  const onDeselectProductLine = useCallback(
    (item) => () => {
      const lines = _.reject(selectedContact?.lines, (el) => el === item);

      const payload = {
        ...selectedContact,
        lines,
      };

      dispatch(setSelectedContact(payload));
    },
    [dispatch, selectedContact],
  );

  const onClickState = useCallback(
    (value) => {
      const payload = {
        ...selectedContact,
        markets: [...selectedContact?.markets, value],
      };

      dispatch(setSelectedContact(payload));
      setStateSearchValue('');
    },
    [dispatch, selectedContact],
  );

  const onRemoveState = useCallback(
    (item) => () => {
      const markets = _.reject(selectedContact?.markets, (el) => el === item);

      const payload = {
        ...selectedContact,
        markets,
      };

      dispatch(setSelectedContact(payload));
      setCodes((prev) => [...prev, item]);
    },
    [dispatch, selectedContact],
  );

  const onClearState = useCallback(() => {
    setStateSearchValue('');
  }, []);

  const onChangeState = useCallback((event) => {
    setStateSearchValue(event.target.value);
  }, []);

  const onChangeInput = useCallback(
    (event, target) => {
      const payload = {
        ...selectedContact,
        [target]: event.target.value,
      };

      dispatch(setSelectedContact(payload));
    },
    [dispatch, selectedContact],
  );

  const onChangeGroupSizeInput = useCallback(
    (event, target, maximum) => {
      if (parseInt(event.target.value) < 0) {
        const payload = {
          ...selectedContact,
          [target]: 0,
        };

        return dispatch(setSelectedContact(payload));
      }

      if (parseInt(event.target.value) > maximum) {
        const payload = {
          ...selectedContact,
          [target]: parseInt(maximum),
        };

        return dispatch(setSelectedContact(payload));
      }

      const payload = {
        ...selectedContact,
        [target]: event.target.value,
      };

      dispatch(setSelectedContact(payload));
    },
    [dispatch, selectedContact],
  );

  const onFocusInput = useCallback(
    (event, target) => {
      if (Number(event.target.value) === 0) {
        const payload = {
          ...selectedContact,
          [target]: '',
        };

        return dispatch(setSelectedContact(payload));
      }
    },
    [dispatch, selectedContact],
  );

  const onBlurInput = useCallback(
    (event, target) => {
      if (!event.target.value && target === 'min_group_size') {
        const payload = {
          ...selectedContact,
          [target]: originalContactData?.min_group_size,
        };

        return dispatch(setSelectedContact(payload));
      }

      if (!event.target.value && target === 'max_group_size') {
        const payload = {
          ...selectedContact,
          [target]: originalContactData?.max_group_size,
        };

        return dispatch(setSelectedContact(payload));
      }
    },
    [dispatch, selectedContact, originalContactData],
  );

  return (
    <Modal type="small" closeButtonType="inside" onClose={onCloseEditModal}>
      <form className={classes.ModalWrapper} onSubmit={onUpdateContact}>
        <div className={classes.ModalTitle}>Edit Contact</div>
        <div className={classes.ModalContent}>
          <div>
            <Input
              label="Contact Name"
              value={selectedContact?.display_name}
              onChange={(event) => onChangeInput(event, 'display_name')}
              isRequired
            />
            <Input
              label="Contact Email"
              type="email"
              value={selectedContact?.email}
              onChange={(event) => onChangeInput(event, 'email')}
              isDisabled={selectedContact?.email}
              isRequired
            />
            <Input
              label="Carrier Name"
              value={selectedContact?.issuer_name}
              onChange={(event) => onChangeInput(event, 'issuer_name')}
              isRequired
            />
            <div className={classes.ModalContentGroupSize}>
              <Input
                label="Minimum Group Size"
                type="default-number"
                value={selectedContact?.min_group_size}
                onChange={(event) => onChangeGroupSizeInput(event, 'min_group_size', 10000000)}
                onFocus={(event) => onFocusInput(event, 'min_group_size')}
                onBlur={(event) => onBlurInput(event, 'min_group_size')}
                isRequired
              />
              <Input
                label="Maximum Group Size"
                type="default-number"
                value={selectedContact?.max_group_size}
                onChange={(event) => onChangeGroupSizeInput(event, 'max_group_size', 10000000)}
                onFocus={(event) => onFocusInput(event, 'max_group_size')}
                onBlur={(event) => onBlurInput(event, 'max_group_size')}
                isRequired
              />
            </div>
          </div>
          <div className={classes.ModalContentMarkets}>
            <div className={classes.ModalContentTitle}>Markets</div>
            <div className={classes.ModalContentMarketsSelected}>
              {selectedContact?.markets?.map((item) => (
                <div key={item} className={classes.ModalContentMarketsSelectedItem}>
                  {item}
                  <i
                    className={`${classes.ModalContentMarketsSelectedItemIcon} fa fa-times`}
                    onClick={onRemoveState(item)}
                  />
                </div>
              ))}
            </div>
            <div>
              <SearchSelect
                value={stateSearchValue}
                onClick={onClickState}
                onChange={onChangeState}
                onClear={onClearState}
                options={codes}
              />
            </div>
          </div>
          <div className={classes.ModalContentLines}>
            <div className={classes.ModalContentTitle}>Lines</div>
            <div className={classes.ModalContentLinesWrapper}>
              <div className={classes.ModalContentLinesItem}>
                <div>Medical</div>
                <div className={classes.ModalContentLinesItemImage}>
                  {selectedContact?.lines?.includes('medical') ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeselectProductLine('medical')}
                    />
                  ) : (
                    <img
                      src={PurpleCircle}
                      alt="PurpleCircle"
                      onClick={onSelectProductLine('medical')}
                    />
                  )}
                </div>
              </div>
              <div className={classes.ModalContentLinesItem}>
                <div>Dental</div>
                <div className={classes.ModalContentLinesItemImage}>
                  {selectedContact?.lines?.includes('dental') ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeselectProductLine('dental')}
                    />
                  ) : (
                    <img
                      src={PurpleCircle}
                      alt="PurpleCircle"
                      onClick={onSelectProductLine('dental')}
                    />
                  )}
                </div>
              </div>
              <div className={classes.ModalContentLinesItem}>
                <div>Vision</div>
                <div className={classes.ModalContentLinesItemImage}>
                  {selectedContact?.lines?.includes('vision') ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeselectProductLine('vision')}
                    />
                  ) : (
                    <img
                      src={PurpleCircle}
                      alt="PurpleCircle"
                      onClick={onSelectProductLine('vision')}
                    />
                  )}
                </div>
              </div>
              <div className={classes.ModalContentLinesItem}>
                <div>Life</div>
                <div className={classes.ModalContentLinesItemImage}>
                  {selectedContact?.lines?.includes('life') ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeselectProductLine('life')}
                    />
                  ) : (
                    <img
                      src={PurpleCircle}
                      alt="PurpleCircle"
                      onClick={onSelectProductLine('life')}
                    />
                  )}
                </div>
              </div>
              <div className={classes.ModalContentLinesItem}>
                <div>Disability</div>
                <div className={classes.ModalContentLinesItemImage}>
                  {selectedContact?.lines?.includes('disability') ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeselectProductLine('disability')}
                    />
                  ) : (
                    <img
                      src={PurpleCircle}
                      alt="PurpleCircle"
                      onClick={onSelectProductLine('disability')}
                    />
                  )}
                </div>
              </div>
              <div className={classes.ModalContentLinesItem}>
                <div>Accident</div>
                <div className={classes.ModalContentLinesItemImage}>
                  {selectedContact?.lines?.includes('accident') ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeselectProductLine('accident')}
                    />
                  ) : (
                    <img
                      src={PurpleCircle}
                      alt="PurpleCircle"
                      onClick={onSelectProductLine('accident')}
                    />
                  )}
                </div>
              </div>
              <div className={classes.ModalContentLinesItem}>
                <div>Hospital</div>
                <div className={classes.ModalContentLinesItemImage}>
                  {selectedContact?.lines?.includes('hospital') ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeselectProductLine('hospital')}
                    />
                  ) : (
                    <img
                      src={PurpleCircle}
                      alt="PurpleCircle"
                      onClick={onSelectProductLine('hospital')}
                    />
                  )}
                </div>
              </div>
              <div className={classes.ModalContentLinesItem}>
                <div>Critical Illness</div>
                <div className={classes.ModalContentLinesItemImage}>
                  {selectedContact?.lines?.includes('critical_illness') ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeselectProductLine('critical_illness')}
                    />
                  ) : (
                    <img
                      src={PurpleCircle}
                      alt="PurpleCircle"
                      onClick={onSelectProductLine('critical_illness')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.ModalFooter}>
          <Button title="Update" type="primary" buttonType="submit" isLoading={isLoadingUpdate} />
          <Button title="Cancel" type="secondary" onClick={onCloseEditModal} />
        </div>
      </form>
    </Modal>
  );
};
