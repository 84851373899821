import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getBasePlanQuote, setSelectedQuotes } from 'src/store/proposals';
import { groupMembersSelector } from 'src/store/groupMembers';

import { inNetworkRegex } from 'src/constants/regularExpression';
import { titleCase, currencyFormatter } from 'src/constants/currency';
import {
  calculateEmployeeTotal,
  calculateEmployerCost,
  calculateEmployerTotal,
  calculateMemberRate,
  calculatePlanTotal,
  sumPremium,
} from 'src/utils/exportXls';

import { Button } from '../Button/Button';

import ArrowImage from 'src/assets/broker/arrow.svg';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import classes from './selectedQuotesTable.module.scss';

export const SelectedQuotesTable = (props) => {
  const { selectedQoutes, quotesMetaData, accountProposalData, isEdit = false } = props;
  const dispatch = useDispatch();

  const members = useSelector(groupMembersSelector);
  const basePlanQuote = useSelector(getBasePlanQuote);

  const [isEmployee, setIsEmployee] = useState(false);
  const [isEmployer, setIsEmployer] = useState(false);
  const [isTotal, setIsTotal] = useState(false);

  const accountMembers = accountProposalData?.groups?.map((group) => group?.members)[0];

  const basePlanQuoteId = selectedQoutes.find(
    (item) => item.id === quotesMetaData?.quote?.base_plan_id,
  );

  const selectedBasePlanQuote = !isEmpty(basePlanQuoteId) ? basePlanQuoteId : basePlanQuote;

  const updatedSelectedQuotes = sumPremium(
    selectedQoutes,
    members.length ? members : accountMembers,
    quotesMetaData?.quote,
    null,
    selectedBasePlanQuote,
  );

  const handleEmployee = useCallback(() => {
    setIsEmployee((prev) => !prev);
  }, []);

  const handleEmployer = useCallback(() => {
    setIsEmployer((prev) => !prev);
  }, []);

  const handleTotal = useCallback(() => {
    setIsTotal((prev) => !prev);
  }, []);

  const onDeselectQuote = useCallback(
    (id) => () => {
      const updatedSelectedQuotes = _.reject(selectedQoutes, (el) => el.id === id);

      dispatch(setSelectedQuotes(updatedSelectedQuotes));
    },
    [dispatch, selectedQoutes],
  );

  const moveLeft = useCallback(
    (id) => () => {
      const quotes = _.cloneDeep(selectedQoutes);

      const index = quotes.findIndex((item) => item.id === id);
      if (index > 0) {
        const data = [...quotes];
        [data[index - 1].order_id, data[index].order_id] = [
          data[index].order_id,
          data[index - 1].order_id,
        ];

        dispatch(setSelectedQuotes(data.sort((a, b) => a.order_id - b.order_id)));
      }
    },
    [dispatch, selectedQoutes],
  );

  const moveRight = useCallback(
    (id) => () => {
      const quotes = _.cloneDeep(selectedQoutes);
      const index = quotes.findIndex((item) => item.id === id);
      if (index < quotes.length - 1) {
        const data = [...quotes];
        [data[index + 1].order_id, data[index].order_id] = [
          data[index].order_id,
          data[index + 1].order_id,
        ];
        dispatch(setSelectedQuotes(data.sort((a, b) => a.order_id - b.order_id)));
      }
    },
    [dispatch, selectedQoutes],
  );

  const employeeIconStyles = classNames({
    [classes.Icon]: !isEmployee,
    [classes.IconActive]: isEmployee,
  });

  const employerIconStyles = classNames({
    [classes.Icon]: !isEmployer,
    [classes.IconActive]: isEmployer,
  });

  const totalIconStyles = classNames({
    [classes.Icon]: !isTotal,
    [classes.IconActive]: isTotal,
  });

  const selectedPayer = quotesMetaData?.quote?.contribution_source;

  return (
    <div className={classes.SelectedRatesTable}>
      <table style={{ width: '100%' }}>
        <tbody>
          {updatedSelectedQuotes[0]?.current_plan_name && (
            <tr>
              <th>Current Plan Name</th>
              {updatedSelectedQuotes?.map((item, i) => (
                <td key={item.id + i}>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  {item.current_plan_name}
                  {item.id === basePlanQuoteId?.id && (
                    <span className={classes.BasePlan}>Base Plan</span>
                  )}
                </td>
              ))}
            </tr>
          )}
          {isEdit && selectedQoutes.length !== 1 && (
            <tr>
              <th></th>
              {updatedSelectedQuotes?.map((item, i) => (
                <td key={item.id + i}>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <div className={classes.SortIcons}>
                    <div></div>
                    {i !== 0 && (
                      <div className={classes.SortIconsItem} onClick={moveLeft(item.id)}>
                        <ChevronLeft />
                      </div>
                    )}
                    {i !== selectedQoutes.length - 1 && (
                      <div className={classes.SortIconsItem} onClick={moveRight(item.id)}>
                        <ChevronRight />
                      </div>
                    )}
                  </div>
                </td>
              ))}
            </tr>
          )}
          <tr>
            <th></th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={item.logo_url} className={classes.SelectedRatesLogo} />
              </td>
            ))}
          </tr>
          <tr>
            <th>Carrier</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item.carrier_name}</td>
            ))}
          </tr>
          <tr>
            <th>Plan Name</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i} className={classes.PlanName}>
                {item.display_name}
              </td>
            ))}
          </tr>
          <tr>
            <th>Plan Type</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item.plan_type}</td>
            ))}
          </tr>
          <tr>
            <th>Network Size</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.network_size?.toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <th>Tier</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{titleCase(item.level)}</td>
            ))}
          </tr>
          <tr>
            <th>Primary Care Physician Copay</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.primary_care_physician?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Specialist Copay</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.specialist}</td>
            ))}
          </tr>
          <tr>
            <th>Individual Deductible</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>
                {item?.individual_medical_deductible?.match(inNetworkRegex)[1]}
              </td>
            ))}
          </tr>
          <tr>
            <th>Family Deductible</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.family_medical_deductible?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Inpatient Facility</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.inpatient_facility?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Coinsurance</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.plan_coinsurance}</td>
            ))}
          </tr>
          <tr>
            <th>Individual Out-of-Pocket</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.individual_medical_moop?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Family Out-of-Pocket</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.family_medical_moop?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Emergency Room</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.emergency_room}</td>
            ))}
          </tr>
          <tr>
            <th>Urgent Care</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.urgent_care}</td>
            ))}
          </tr>
          <tr>
            <th>Benefits Summary</th>
            {updatedSelectedQuotes?.map((item, i) =>
              item?.benefits_summary_url ? (
                <td key={item.id + i} className={classes.TableLink}>
                  <a href={item?.benefits_summary_url} target="_blank" rel="noreferrer">
                    Click Here
                  </a>
                </td>
              ) : (
                <td key={item.id + i}>None available</td>
              ),
            )}
          </tr>
          <tr>
            <th onClick={handleEmployee} id="employee">
              Employer
              <img src={ArrowImage} className={employeeIconStyles} alt="ArrowImage" />
            </th>

            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i} style={{ fontWeight: isEmployee ? 'bold' : 400 }}>
                {selectedPayer === 'employee' && (
                  <>
                    {quotesMetaData?.quote?.quote_type === 'advanced'
                      ? calculateEmployeeTotal(
                          item,
                          quotesMetaData?.quote,
                          members,
                          selectedBasePlanQuote,
                        )
                      : currencyFormatter(item?.employee_cost)}
                  </>
                )}

                {selectedPayer === 'employer' && (
                  <>
                    {quotesMetaData?.quote?.quote_type === 'advanced'
                      ? calculateEmployerTotal(
                          item,
                          quotesMetaData?.quote,
                          members,
                          selectedBasePlanQuote,
                        )
                      : currencyFormatter(item?.employer_cost)}
                  </>
                )}
              </td>
            ))}
          </tr>
          {isEmployee && (
            <>
              {accountProposalData?.groups?.map((group) => {
                return group?.members?.map((member) => (
                  <tr
                    key={member.id}
                    id="employeeTable"
                    className={isEmployee ? classes.VisibleTable : classes.HiddenTable}
                  >
                    <td style={{ paddingLeft: '30px' }}>
                      {member?.first_name} {member?.last_name}
                    </td>
                    {updatedSelectedQuotes.map((item, i) => (
                      <td key={item.id + i}>
                        {selectedPayer === 'employee' && (
                          <>
                            {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                              <>
                                {member.current_medical_plan_name === item.current_plan_name
                                  ? currencyFormatter(
                                      calculateMemberRate(
                                        member,
                                        item,
                                        quotesMetaData?.quote,
                                        selectedBasePlanQuote,
                                      ),
                                    )
                                  : '--'}
                              </>
                            ) : (
                              <>
                                {currencyFormatter(
                                  calculateMemberRate(
                                    member,
                                    item,
                                    quotesMetaData?.quote,
                                    selectedBasePlanQuote,
                                  ),
                                )}
                              </>
                            )}
                          </>
                        )}

                        {selectedPayer === 'employer' && (
                          <>
                            {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                              <>
                                {member.current_medical_plan_name === item.current_plan_name
                                  ? currencyFormatter(
                                      calculateEmployerCost(
                                        member,
                                        item,
                                        quotesMetaData?.quote,
                                        selectedBasePlanQuote,
                                      ),
                                    )
                                  : '--'}
                              </>
                            ) : (
                              <>
                                {currencyFormatter(
                                  calculateEmployerCost(
                                    member,
                                    item,
                                    quotesMetaData?.quote,
                                    selectedBasePlanQuote,
                                  ),
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ));
              })}
            </>
          )}
          <tr>
            <th onClick={handleEmployer} id="employer">
              Employee
              <img src={ArrowImage} className={employerIconStyles} alt="ArrowImage" />
            </th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i} style={{ fontWeight: isEmployer ? 'bold' : 400 }}>
                {selectedPayer === 'employee' && (
                  <>
                    {quotesMetaData?.quote?.quote_type === 'advanced'
                      ? calculateEmployerTotal(
                          item,
                          quotesMetaData?.quote,
                          members,
                          selectedBasePlanQuote,
                        )
                      : currencyFormatter(item?.employer_cost)}
                  </>
                )}
                {selectedPayer === 'employer' && (
                  <>
                    {quotesMetaData?.quote?.quote_type === 'advanced'
                      ? calculateEmployeeTotal(
                          item,
                          quotesMetaData?.quote,
                          members,
                          selectedBasePlanQuote,
                        )
                      : currencyFormatter(item?.employee_cost)}
                  </>
                )}
              </td>
            ))}
          </tr>
          {isEmployer && (
            <>
              {accountProposalData?.groups?.map((group) => {
                return group?.members?.map((member) => (
                  <tr
                    key={member.id}
                    id="employerTable"
                    className={isEmployer ? classes.VisibleTable : classes.HiddenTable}
                  >
                    <td style={{ paddingLeft: '30px' }}>
                      {member?.first_name} {member?.last_name}
                    </td>
                    {updatedSelectedQuotes.map((item, i) => (
                      <td key={item.id + i}>
                        {selectedPayer === 'employee' && (
                          <>
                            {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                              <>
                                {member.current_medical_plan_name === item.current_plan_name
                                  ? currencyFormatter(
                                      calculateEmployerCost(
                                        member,
                                        item,
                                        quotesMetaData?.quote,
                                        selectedBasePlanQuote,
                                      ),
                                    )
                                  : '--'}
                              </>
                            ) : (
                              <>
                                {currencyFormatter(
                                  calculateEmployerCost(
                                    member,
                                    item,
                                    quotesMetaData?.quote,
                                    selectedBasePlanQuote,
                                  ),
                                )}
                              </>
                            )}
                          </>
                        )}

                        {selectedPayer === 'employer' && (
                          <>
                            {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                              <>
                                {member.current_medical_plan_name === item.current_plan_name
                                  ? currencyFormatter(
                                      calculateMemberRate(
                                        member,
                                        item,
                                        quotesMetaData?.quote,
                                        selectedBasePlanQuote,
                                      ),
                                    )
                                  : '--'}
                              </>
                            ) : (
                              <>
                                {currencyFormatter(
                                  calculateMemberRate(
                                    member,
                                    item,
                                    quotesMetaData?.quote,
                                    selectedBasePlanQuote,
                                  ),
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ));
              })}
            </>
          )}
          <tr>
            <th onClick={handleTotal} id="total">
              Total <img src={ArrowImage} className={totalIconStyles} alt="ArrowImage" />
            </th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i} style={{ fontWeight: isTotal ? 'bold' : 400 }}>
                {quotesMetaData?.quote?.quote_type === 'advanced'
                  ? calculatePlanTotal(item, quotesMetaData?.quote, members, selectedBasePlanQuote)
                  : currencyFormatter(item?.total_premium)}
              </td>
            ))}
          </tr>
          {isTotal && (
            <>
              {accountProposalData?.groups?.map((group) => {
                return group?.members?.map((member) => (
                  <tr
                    key={member.id}
                    className={isTotal ? classes.VisibleTable : classes.HiddenTable}
                  >
                    <td style={{ paddingLeft: '30px' }}>
                      {member?.first_name} {member?.last_name}
                    </td>
                    {updatedSelectedQuotes.map((item, i) => (
                      <td key={item.id + i}>
                        {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                          <>
                            {member.current_medical_plan_name === item.current_plan_name
                              ? currencyFormatter(
                                  calculateMemberRate(
                                    member,
                                    item,
                                    quotesMetaData?.quote,
                                    selectedBasePlanQuote,
                                    false,
                                  ),
                                )
                              : '--'}
                          </>
                        ) : (
                          <>
                            {currencyFormatter(
                              calculateMemberRate(
                                member,
                                item,
                                quotesMetaData?.quote,
                                selectedBasePlanQuote,
                                false,
                              ),
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ));
              })}
            </>
          )}

          {isEdit && (
            <tr>
              <th></th>
              {updatedSelectedQuotes?.map((item, i) => (
                <td key={item.id + i}>
                  <Button type="inverse" title="Deselect" onClick={onDeselectQuote(item.id)} />
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

SelectedQuotesTable.propTypes = {
  selectedQoutes: PropTypes.array,
  quotesMetaData: PropTypes.object,
  accountProposalData: PropTypes.object,
};
