import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useParams, useLocation } from 'react-router-dom';
import { auth } from 'src/app/database';

import { Loader, LoaderWrapper } from 'src/components';

import { ROUTE } from 'src/constants/routes';
import { setAgencyTeamId } from 'src/store/agency';

import classes from './styles.module.scss';

export const ProtectedRoute = () => {
  const dispatch = useDispatch();

  const [user, loading] = useAuthState(auth);
  const params = useParams();
  const teamId = params?.teamId;

  const [defaultRoute, setDefaultRoute] = useState(ROUTE.LOGIN);

  const { pathname } = useLocation();

  const issuerId = pathname.split('/')[2];

  const isIssuer = ['issuer', 'rep'].includes(pathname.split('/')[1]);

  useEffect(() => {
    if (isIssuer) {
      setDefaultRoute(`${ROUTE.ISSUER_LOGIN}?continueUrl=${pathname}`);
    }
  }, [isIssuer, pathname, issuerId]);

  useEffect(() => {
    if (teamId) {
      dispatch(setAgencyTeamId(teamId));
    }
  }, [dispatch, teamId]);

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : user ? (
    <div className={classes.RouteWrapper}>
      <div className={classes.RouteWrapperContent}>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={defaultRoute} />
  );
};
