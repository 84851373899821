import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  createCustomToken,
  getAdminReps,
  isLoadingRepUsersSelector,
  repUsersMetaSelector,
  repUsersSelector,
} from 'src/store/admin';

import { Status } from '../Status/Status';
import { Button } from '@mui/material';

export const useTable = (props) => {
  const { isImpersonate = true } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const repUsers = useSelector(repUsersSelector);
  const repUsersMeta = useSelector(repUsersMetaSelector);
  const isLoadingRepUsers = useSelector(isLoadingRepUsersSelector);

  const [, setCookie] = useCookies(['is_impersonate']);

  const onClickImpersonate = useCallback(
    (user_id) => async () => {
      const payload = {
        user_id,
        navigate,
      };

      await dispatch(createCustomToken(payload));
      setCookie('is_impersonate', 'true', { path: '/' });
    },
    [dispatch, navigate, setCookie],
  );

  const build = {
    title: isImpersonate ? 'Rep Impersonate' : '',
    filters: [
      {
        id: 'q',
        label: 'Search',
        type: 'search-input',
      },
    ],
    columns: [
      {
        id: 'email',
        name: 'Rep Email',
        sortable: true,
      },
      {
        id: 'last_active',
        name: 'Last Active',
        sortable: true,
      },
      {
        id: 'display_name',
        name: 'Broker Name',
        sortable: true,
      },
      {
        id: 'status',
        name: 'Status',
        value: (item) => <Status status={item?.status} />,
        sortable: true,
      },
      {
        id: '1',
        name: '',
        value: (item) =>
          isImpersonate ? (
            <Button variant="contained" onClick={onClickImpersonate(item?.id)} size="small">
              Impersonate
            </Button>
          ) : null,
        sortable: false,
      },
    ],
    data: repUsers,
    meta: repUsersMeta,
    api: {
      get: getAdminReps,
    },
    is_loading: isLoadingRepUsers,
  };

  return { build };
};
