import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  getIssuerBrokers,
  isLoadingIssuerBrokersSelector,
  issuerBrokersMetaSelector,
  issuerBrokersSelector,
} from 'src/store/browseBrokers';

import { coverageOptions, dateOptions } from 'src/features/issuer/issuerRequests/issuerOptions';

export const useTable = () => {
  const brokersData = useSelector(issuerBrokersSelector);
  const brokersDataMeta = useSelector(issuerBrokersMetaSelector);
  const isLoading = useSelector(isLoadingIssuerBrokersSelector);

  const build = {
    title: 'Browse RFPs',
    filters: [
      {
        id: 'coverage_type',
        type: 'select',
        label: 'Coverage',
        options: coverageOptions,
      },

      {
        id: 'due_date',
        type: 'select',
        label: 'Due Date',
        options: dateOptions,
      },
      {
        id: 'q',
        label: 'Search',
        type: 'search-input',
      },
    ],
    columns: [
      {
        id: 'sic_code',
        name: 'Account SIC',
        sortable: true,
      },
      {
        id: 'account_zip_code',
        name: 'Account Zip Code',
        sortable: false,
      },
      {
        id: 'coverage',
        name: 'Coverage',
        value: (item) => item?.lines_of_coverage?.join(', '),
        sortable: false,
      },
      {
        id: 'group_size',
        name: 'Employee Count',
        sortable: false,
      },
      {
        id: 'broker',
        name: 'Broker',
        value: (item) => {
          return (
            <>
              {item?.broker?.display_name}
              <br />
              {item?.broker?.address_street && `${item?.broker?.address_street} `}
              {item?.broker?.address_city && `${item?.broker?.address_city}, `}
              {item?.broker?.address_state && `${item?.broker?.address_state} `}
              {item?.broker?.address_zip_code && `${item?.broker?.address_zip_code}`}
              <br />
              {item?.broker?.email && `${item?.broker?.email}`}
              <br />
              {item?.broker?.phone_number && `${item?.broker?.phone_number}`}
            </>
          );
        },
        sortable: false,
      },
      {
        id: 'effective_date',
        name: 'Effective Date',
        value: (item) => moment(item?.effective_date).format('l'),
        sortable: true,
      },
      {
        id: 'due_date',
        name: 'Due Date',
        value: (item) => moment(item?.due_date).format('l'),
        sortable: true,
      },
    ],
    data: brokersData,
    meta: brokersDataMeta,
    api: {
      get: getIssuerBrokers,
    },
    is_loading: isLoading,
  };

  return { build };
};
