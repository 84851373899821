import React, { useCallback, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';

import { sendPasswordResetEmail } from 'src/store/app';
import {
  getIssuerDataSelector,
  updateProfileData,
  getIsEditProfileSelector,
  setIsEditProfile,
} from 'src/store/issuer';

import { ERROR } from 'src/constants/errorNames';
import { phoneE164Regex } from 'src/constants/regularExpression';
import { formatPhoneNumber } from 'src/utils/format';

import { UploadImage } from 'src/features/uploadImage/UploadImage';
import { Button, DetailsItem, Input, Status, InputPhone } from 'src/components';

import classes from './issuerProfile.module.scss';

export const IssuerProfile = () => {
  const dispatch = useDispatch();

  const [user] = useAuthState(auth);
  const issuerData = useSelector(getIssuerDataSelector);
  const isEditMode = useSelector(getIsEditProfileSelector);

  const [fullName, setFullName] = useState(user?.displayName);
  const [email, setEmail] = useState(user?.email);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [previewImage, setPreviewImage] = useState(issuerData?.photo_url);

  useEffect(() => {
    dispatch(setIsEditProfile(false));
  }, [dispatch]);

  useEffect(() => {
    if (isEditMode) {
      setPhoneNumber(issuerData?.phone_number ?? '');
    }
  }, [isEditMode, issuerData]);

  useEffect(() => {
    if (issuerData?.photo_url) {
      setPreviewImage(issuerData?.photo_url);
    }
  }, [issuerData]);

  const onSetPreviewImage = useCallback((image) => {
    setPreviewImage(image);
  }, []);

  const onChangeFullName = useCallback((event) => {
    setFullName(event.target.value);
  }, []);

  const onChangeEmail = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onChangePhoneNumber = useCallback((phone) => {
    setPhoneNumber(phone);
  }, []);

  const onClickEditProfile = useCallback(() => {
    dispatch(setIsEditProfile((prev) => !prev));
  }, [dispatch]);

  const onSendResetPassword = useCallback(() => {
    dispatch(sendPasswordResetEmail({ email, replacedUrl: '/issuer/settings', isIssuer: true }));
  }, [dispatch, email]);

  const onSaveUpdatedUserProfile = useCallback(() => {
    if (phoneNumber.includes('+') && !phoneNumber.match(phoneE164Regex)) {
      return toast(ERROR.INVALID_PHONE_NUMBER, { type: 'warning' });
    }

    if (!phoneNumber.includes('+') && !formatPhoneNumber(phoneNumber).match(phoneE164Regex)) {
      return toast(ERROR.INVALID_PHONE_NUMBER, { type: 'warning' });
    }

    const payload = {
      email,
      fullName,
      photo_url: previewImage,
      phone_number: !phoneNumber.includes('+') ? formatPhoneNumber(phoneNumber) : phoneNumber,
    };
    dispatch(updateProfileData(payload));
  }, [dispatch, email, fullName, phoneNumber, previewImage]);

  const onCancelEditProfile = useCallback(() => {
    dispatch(setIsEditProfile(false));
    setFullName(user?.displayName);
    setEmail(user?.email);
    setPreviewImage(issuerData?.photo_url || '');
  }, [dispatch, user, issuerData]);

  return (
    <>
      <div>
        <div className={classes.SettingsContentWrapper}>
          <div className={classes.SettingsContentImage}>
            <UploadImage
              path={`users/${user?.uid}/avatar`}
              previewImage={previewImage}
              setPreviewImage={onSetPreviewImage}
              isOnlyImage={!isEditMode}
              type="profile"
              isCirclePreviewImage
            />
          </div>
          <div className={classes.TableContent}>
            <table className={classes.SettingsTable}>
              <tbody>
                <DetailsItem
                  title="Full Name"
                  value={
                    !isEditMode ? (
                      user?.displayName
                    ) : (
                      <Input value={fullName} onChange={onChangeFullName} />
                    )
                  }
                />
                <DetailsItem
                  title="Email"
                  value={
                    !isEditMode ? user?.email : <Input value={email} onChange={onChangeEmail} />
                  }
                />
                <DetailsItem
                  title="Phone Number"
                  value={
                    !isEditMode ? (
                      issuerData?.phone_number
                    ) : (
                      <InputPhone value={phoneNumber} onChange={onChangePhoneNumber} />
                    )
                  }
                />
                {!isEditMode && (
                  <>
                    <DetailsItem
                      title="Password"
                      value={
                        <div>
                          ********
                          <button className={classes.ChangePassword} onClick={onSendResetPassword}>
                            change
                          </button>
                        </div>
                      }
                    />
                    <DetailsItem
                      title="Subscription Status"
                      value={
                        <div className={classes.SettingsStatus}>
                          <Status status={issuerData?.status} />
                        </div>
                      }
                    />
                  </>
                )}
                {isEditMode && (
                  <DetailsItem title="Email Verified" value={user?.emailVerified.toString()} />
                )}
              </tbody>
            </table>
          </div>
        </div>
        {!isEditMode && (
          <div className={classes.EditProfileButton}>
            <Button title="Edit profile" type="secondary" onClick={onClickEditProfile} />
          </div>
        )}
        {isEditMode && (
          <div className={classes.FooterButton}>
            <Button title="Save" type="primary" onClick={onSaveUpdatedUserProfile} />
            <Button title="Cancel" type="secondary" onClick={onCancelEditProfile} />
          </div>
        )}
      </div>
    </>
  );
};
