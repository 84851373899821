import React from 'react';

import { CustomTable } from '../CustomTable/CustomTable';
import { useTable } from './useTable';

export const AdminRepsTable = (props) => {
  const { isImpersonate = true } = props;

  const { build } = useTable({ isImpersonate });

  return <CustomTable build={build} />;
};
