import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useTable } from './useTable';

import {
  isVisibleEditContactSelector,
  originalContactSelector,
  selectedContactSelector,
  setIsVisibleEditContact,
  setOriginalContact,
  setSelectedContact,
  updateAgencyContact,
  updateBrokerContact,
} from 'src/store/contacts';
import { getBrokerData } from 'src/store/broker';

import { CustomTable } from '../CustomTable/CustomTable';
import { EditContactModal } from '../EditContactModal/EditContactModal';

export const BrokerContactsTable = (props) => {
  const dispatch = useDispatch();

  const { build } = useTable(props);

  const params = useParams();
  const org_id = params?.teamId;

  const brokerData = useSelector(getBrokerData);

  const selectedContact = useSelector(selectedContactSelector);
  const originalContact = useSelector(originalContactSelector);
  const isModal = useSelector(isVisibleEditContactSelector);

  const onCloseEditModal = useCallback(() => {
    dispatch(setSelectedContact({}));
    dispatch(setOriginalContact({}));
    dispatch(setIsVisibleEditContact(false));
  }, [dispatch]);

  const onUpdateContact = useCallback(
    (event) => {
      event.preventDefault();

      if (selectedContact?.min_group_size > selectedContact?.max_group_size) {
        return toast('Maximum group size should be grader than or equals to minimum group size!', {
          type: 'warning',
        });
      }

      if (!org_id) {
        dispatch(
          updateBrokerContact({ broker_id: brokerData?.id, ...selectedContact, team_id: org_id }),
        );
      }

      if (org_id) {
        dispatch(updateAgencyContact({ ...selectedContact, org_id }));
      }
    },
    [dispatch, brokerData?.id, selectedContact, org_id],
  );

  return (
    <div>
      {isModal && (
        <EditContactModal
          onCloseEditModal={onCloseEditModal}
          onUpdateContact={onUpdateContact}
          selectedContact={selectedContact}
          originalContactData={originalContact}
          setSelectedContact={setSelectedContact}
        />
      )}

      <CustomTable build={build} />
    </div>
  );
};
